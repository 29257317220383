import axios, { AxiosRequestConfig } from "axios";
import { Analytics } from "./analytic";

export interface Link {
  url: string;
  title: string;
}

export enum MarkerType {
  date = 0,
  number
}

export enum ShowIcons {
  noShow = 0,
  show
}

export interface Marker {
  name: string;
}

const baseUrl = "https://us-central1-mapsforall-96ddd.cloudfunctions.net";
// const baseUrl = "http://localhost:5000/mapsforall-96ddd/us-central1";

export class MapService {
  baseUrl: string;
  analytics: Analytics;
  user: firebase.User;

  constructor(user: firebase.User) {
    this.baseUrl = baseUrl;
    this.user = user;
    this.analytics = new Analytics(user.uid);
  }

  public async getNotification() {
    const { data } = await axios.get(
      `${baseUrl}/getNotification`,
      await this.getConfig()
    );
    this.analytics.log("getNotification");
    return data;
  }

  // USER LEVEL
  public async getUserMetaData() {
    const { data } = await axios.get(
      `${baseUrl}/getUserMetaData`,
      await this.getConfig()
    );
    this.analytics.log("getUserMetaData");
    return data;
  }

  public async setLastLoggedIn() {
    await axios.get(`${baseUrl}/setLastLoggedIn`, await this.getConfig());
    this.analytics.log("setLastLoggedIn");
  }

  public async getMaps() {
    const { data } = await axios.get(
      `${baseUrl}/getMaps`,
      await this.getConfig()
    );
    this.analytics.log("getMaps");
    return data;
  }

  // MAP
  public async getMap(title: string) {
    const { data } = await axios.get(
      `${baseUrl}/getMap?map=${title}`,
      await this.getConfig()
    );
    this.analytics.log("getMap", ["map_title", title]);
    return data;
  }

  public async createMap(
    title: string,
    icon: string,
    pin: string,
    desc: string,
    link: Link,
    markerType: MarkerType,
    minZoom: number
  ) {
    await axios.post(
      `${baseUrl}/createMap`,
      {
        title,
        icon,
        pin,
        desc,
        link,
        markerType,
        minZoom
      },
      await this.getConfig()
    );
    this.analytics.log("createMap", ["map_title", title]);
  }

  public async updateMap(
    title: string,
    icon: string,
    pin: string,
    newTitle: string,
    desc: string,
    link: Link,
    showIcons: ShowIcons,
    minZoom: number
  ) {
    await axios.post(
      `${baseUrl}/updateMap`,
      {
        title,
        icon,
        pin,
        newTitle,
        desc,
        link,
        showIcons,
        minZoom
      },
      await this.getConfig()
    );
    this.analytics.log(
      "updateMap",
      ["map_title", title],
      ["new_map_title", newTitle]
    );
  }

  public async deleteMap(title: string) {
    const { data } = await axios.get(
      `${baseUrl}/deleteMap?title=${title}`,
      await this.getConfig()
    );
    this.analytics.log("deleteMap", ["map_title", title]);
  }

  public async publishMap(title: string) {
    await axios.get(`${baseUrl}/publish?map=${title}`, await this.getConfig());
    this.analytics.log("publishMap", ["map_title", title]);
  }

  public async importData(title: string, file: File, override: boolean) {
    let config = await this.getConfig();
    const importData = await new Response(file).text();
    config["headers"]["Content-Type"] =
      file.type === "application/json" ? file.type : "text/plain";

    const { data } = await axios.post(
      `${baseUrl}/importMapData?map=${title}&override=${override}`,
      importData,
      config
    );
    this.analytics.log("importData", ["map_title", title]);
    return data;
  }

  // MARKER
  public async addMarker(title: string, mapId: string, marker: Marker) {
    await axios.post(
      `${baseUrl}/addMarker`,
      {
        title,
        mapId,
        marker
      },
      await this.getConfig()
    );
    this.analytics.log("addMarker", ["map_title", title]);
  }

  public async updateMarker(title: string, mapId: string, marker: Marker) {
    await axios.post(
      `${baseUrl}/updateMarker`,
      {
        title,
        mapId,
        marker
      },
      await this.getConfig()
    );
    this.analytics.log("updateMarker", ["map_title", title]);
  }

  public async deleteMarker(title: string, mapId: string, markerId: string) {
    await axios.get(
      `${baseUrl}/deleteMarker?title=${title}&mapId=${mapId}&markerId=${markerId}`,
      await this.getConfig()
    );
    this.analytics.log("deleteMarker", ["map_title", title]);
  }

  private async getConfig(): Promise<AxiosRequestConfig> {
    const userToken = await this.user.getIdToken(true);
    const requestConfig = {
      headers: { Authorization: "Bearer " + userToken }
    };
    return requestConfig;
  }
}

export async function geocode(
  token: string,
  city: string,
  state: string,
  country: string
) {
  const response = await axios.get(
    `${baseUrl}/geocode?city=${city}&state=${state}&country=${country}`,
    { headers: { Authorization: "Bearer " + token } }
  );

  let analytics = new Analytics();
  analytics.log(
    "geocode",
    ["city", city],
    ["state", state],
    ["country", country]
  );
  return response.data;
}

export async function reverseGeocode(token: string, lat: string, long: string) {
  const response = await axios.get(
    `${baseUrl}/reverseGeocode?lat=${lat}&long=${long}`,
    { headers: { Authorization: "Bearer " + token } }
  );

  let analytics = new Analytics();
  analytics.log("reverseGeocode");
  return response.data;
}
